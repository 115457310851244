import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getDictLists } from '@/filters/fromDict'
import operateRadio from '@/pages/business/components/operateRadio.vue'
// import { validbusinessNo } from '@/utils/validate'
// import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
// import BaseSelect from '@/components/common/base-select/base-select.vue'
import upload from '@/pages/business/components/upload.vue'

// 查询form contract-file-manage-list 合同文件列表
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        maxlength: '20',
        placeholder: '请输入'
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        options: content.gysData,
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: BaseSelect
    },

    {
      label: '业务环节',
      prop: 'processStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('PROCESS_NAME'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect

    },
    {
      label: '审核状态',
      prop: 'approvalStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('REVIEW_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      isHidden: content.fold

    }

  ]
}
// 表格列表
export const dataTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      formatter: row => {
        return row.finBusuinessInfoVO ? row.finBusuinessInfoVO.businessNo : ''
      }
    },
    {
      label: '业务环节',
      prop: 'statusName',
      formatter: row => {
        return row.finBusuinessInfoVO ? row.finBusuinessInfoVO.statusName : ''
      }
    },
    {
      label: '供应商名称',
      prop: 'gysCompanyName',
      align: 'left',
      formatter: row => {
        return row.finBusuinessInfoVO ? row.finBusuinessInfoVO.gysCompanyName : ''
      }
    },
    {
      label: '融资产品',
      prop: 'finaaceProductName',
      formatter: row => {
        return row.finBusuinessInfoVO ? row.finBusuinessInfoVO.finaaceProductName : ''
      }
    },
    {
      label: '融资金额（元）',
      prop: 'applyAmount',
      align: 'right',
      formatter: row => {
        return (row.finBusuinessInfoVO && row.finBusuinessInfoVO.applyAmount ? row.finBusuinessInfoVO.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-')
      }
    },
    {
      label: '审核节点',
      prop: 'processNodeName',
      // align: 'right',
      formatter: row => {
        return row?.finBusuinessInfoVO?.processNodeName ? row.finBusuinessInfoVO.processNodeName : '--'
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatusName',
      formatter: row => {
        return row.finBusuinessInfoVO ? row.finBusuinessInfoVO.reviewStatusName : '-'
      }
    },

    {
      label: '操作',
      prop: 'action',
      width: '100'
    }
  ]
}
// 详情  contract-file-manage-info 合同审核详情
export const infoForm = (content) => {
  const span = 5
  return [
    {
      label: '文件来源：',
      prop: 'businessNo',
      span
    },
    {
      label: '决议时间：',
      prop: 'gysId',
      span
    },
    {
      label: '决议地点：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '文件生成时间：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '保理服务合同编号：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '应到股东数(人)：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '实到股东数(人)：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '保理服务合同签署证人：',
      prop: 'reviewStatus',
      span
    },
    {
      label: '文件签署时间：',
      prop: 'reviewStatus',
      span
    }
  ]
}
// 表格table
export const signDataTable = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '文件名称',
      prop: 'docName',
      'show-overflow-tooltip': true,
      align: 'left',
      'min-width': '150px'
    },
    {
      label: '文件名',
      prop: 'fileName',
      'show-overflow-tooltip': true,
      align: 'left',
      'min-width': '200px'
    },
    {
      label: '盖章类别',
      prop: 'name',
      'min-width': '100px'
    },
    {
      label: '盖章类型',
      prop: 'signerType',
      formatter: row => {
        const [obj] = context.signerType.filter(item => item.dictId === row.signerType)
        return obj ? obj.dictName : ''
      }
    },
    {
      label: '姓名',
      prop: 'personnelName'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personType',
      formatter: row => {
        if (row.personType) {
          const personTypeArr = row.personType.split(',')
          let valueArr = []
          personTypeArr.forEach((elem) => {
            const [obj] = context.personType.filter(item => item.dictId === elem)
            if (obj) {
              valueArr.push(obj.dictName)
            }
          })
          valueArr = valueArr.join(',')
          return valueArr || ''
        } else {
          return ''
        }
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '80',
      HiddenOverflow: true
    }
  ]
}
// 审核表
export const formConfigListx = (content) => {
  // 返回修改、拒绝，【审批意见】为必填
  const flag = content.formdata.reviewStatus === 40 || content.formdata.reviewStatus === 30
  const optionsData = () => {
    const data = Storage.getSession('examine')
    if (data?.completeType === '1') { // 直送回来的只有通过
      return [
        { id: 10, name: '审核通过' }
      ]
    } else {
      return [
        { id: 10, name: '审核通过' },
        { id: 40, name: '返回修改' }
        // { id: 30, name: '拒绝' }
      ]
    }
  }
  return [
    {
      label: '审核结果',
      prop: 'reviewStatus',
      rules: [{ required: true, message: '请选择审核结果', trigger: 'change' }],
      span: 18,
      attrs: {
        placeholder: '请选择',
        options: optionsData()
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeReviewStatus(data)
        }
      }
    },
    {
      label: '附件',
      prop: 'uploadFileId',
      span: 6,
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        label: '上传附件',
        icon: 'iconfont iconshangchuan',
        btnType: 'upload',
        accept: '.jpg,.jpeg,.png,.doc,.docx,.pdf,.xls,.xlsx',
        'http-request': (param) => {
          content.uploadFile(param)
        }
      },
      on: {
        inputSuccess: content.inputSuccess
      },
      tag: upload
    },
    {
      label: '',
      prop: 'radio',
      span: 18,
      tag: operateRadio,
      rules: [{ required: true, message: '请选择', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: [
          {
            value: '40',
            label: '直送'
          },
          {
            value: '20',
            label: '退回'
          }
        ]
      },
      isHidden: !content.backToProcessOptions.length > 0
    },
    {
      label: '选择退回节点',
      prop: 'taskDefinitionKey',
      rules: [{ required: true, message: '请选择回退节点', trigger: 'change' }],
      span: 24,
      attrs: {
        placeholder: '请选择',
        options: content.backToProcessOptions,
        selectedField: ['id', 'name']
      },
      isHidden: !content.backToProcessOptions.length > 0,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeBackToProcess(data)
        }
      }
    },
    {
      label: '审核意见',
      prop: 'reviewOpinion',
      rules: [flag ? { required: true, message: '请输入审核意见', trigger: 'blur' } : ''],
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: '1000',
        rows: 10
      },
      span: 24
    }
  ]
}
