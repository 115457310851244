//签约管理审核
<template>
  <div class="signReview">
    <backTitle></backTitle>
    <div class="contentMain">
      <div class="left">
        <base-table
          class="main-page-table"
          :columns="columns"
          :showPage="false"
          :tableAttrs="{
            stripe: false,
            data: tableData,
            border: true,
            spanMethod: objectSpanMethod,
          }"
          :dataSource.sync="tableData"
          :api="api"
          :loadCount="loadCount"
          :webPage="false"
        >
          >
          <template slot="code" slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
          <template slot="action" slot-scope="scope">
            <icon-button
              content="预览"
              icon="iconfont iconyulan"
              @click="previewfile(scope.row)"
            />
          </template>
        </base-table>
      </div>
      <div class="right">
        <div v-if="type === 'E'">
          <titleTemplate name="申请意见"> </titleTemplate>
          <base-from
            :showBtns="false"
            :componentList="formConfigListCofig"
            :formAttrs="{
              model: formdata,
              labelWidth: '50px',
            }"
            class="formStyle"
            ref="fromdata"
          ></base-from>
          <div class="submit">
            <baseButton label="提交" @click.prevent="submit(true)"></baseButton>
            <baseButton label="关闭" @click.prevent="close"></baseButton>
          </div>
        </div>
        <audit-timeline
          :dataList="finReviewOpinionInfoVO"
          class="auditTimeline"
          :name="'审批详情'"
          :auditInfo="{ fileMakeName: '进行中' }"
        />
      </div>
    </div>
     <!-- 文件预览 -->
    <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileType" :count="count" />
  </div>
</template>
<script>
import backTitle from '@/pages/business/components/backtitle.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { signDataTable, formConfigListx } from '../utils/config'
import { FilseTypeFilterData } from '../utils/utils'
import { signApi } from '@/api/businessApi'
import { financeAdmittanceApi, instanceNodeApi } from '@/api/financeAdmittanceApi'
import BaseFrom from '@/components/common/base-form/base-form.vue'
import titleTemplate from '@/pages/business/components/titleTemplate.vue'
import AuditTimeline from '@/pages/business/components/auditTimeline.vue'
// import { getDict } from '@/filters/fromDict'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import Storage from '@/utils/storage'
import { getDictLists } from '@/filters/fromDict'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import PreView from '@/components/pre-view/pre-view.vue'

export default {
  components: {
    backTitle,
    BaseTable,
    BaseFrom,
    titleTemplate,
    AuditTimeline,
    baseButton,
    IconButton,
    PreView
  },
  data () {
    return {
      type: '',
      loadCount: 0,
      tableData: [],
      backToProcessOptions: [], // 回退流程选项
      formdata: {
        reviewStatus: 10
      }, // 审核数据
      finReviewOpinionInfoVO: [], // 审核履历数据
      fileData: { fileId: '', fileType: '' },
      count: 0
    }
  },
  computed: {
    columns () {
      return signDataTable(this)
    },
    api () {
      return signApi
    },
    formConfigListCofig () {
      return formConfigListx(this)
    },
    // 盖章类型
    signerType () {
      return getDictLists('SEAL_TYPE')
    },
    // 人员类别
    personType () {
      return getDictLists('PERSONNEL_TYPE')
    }
  },
  created () {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
  },
  mounted () {
    this.getSignDetail()
    this.getdetail()
  },
  methods: {
    // 预览
    previewfile (row) {
      console.log(row, 'row.fileNamerow.fileName')
      if (row.currentFileId && row.fileName) {
        this.fileData.fileId = row.currentFileId
        this.fileData.fileType = row.fileName.substr(row.fileName.lastIndexOf('.') + 1, 4)
        this.count++
      }
    },
    // 提交审核内容-new
    submit () {
      const listData = JSON.parse(sessionStorage.getItem('examine'))
      // const [obj] = this.riskManagerOptions.filter(item => item.riskManagerId === this.formdata.riskManagerId)
      // this.formdata.riskManagerName = obj ? obj.riskManagerName : ''
      this.formdata.processStatus = listData.finBusuinessInfoVO.statusCode
      this.formdata.businessId = listData.finBusuinessInfoVO.keyId
      this.formdata.operationPost = this.operationPost
      this.formdata.taskId = listData.taskId
      this.formdata.taskName = listData.taskName
      this.formdata.actionUrl = listData.actionUrl
      this.formdata.isfApproval = false
      this.formdata.batchNum = listData.finBusuinessInfoVO.batchNum
      // 在审核的时候，返回修改40.但是现在合同制作、签章流程传20
      // const statusCodeArr = [
      //   'CONTRACT',
      //   'SIGNATURE',
      //   'SIGNATURE_SIGN',
      //   'CONTRACT_SIGN'
      // ]
      // if (
      //   statusCodeArr.some(
      //     (item) => item === listData.finBusuinessInfoVO.statusCode
      //   ) &&
      //   this.formdata.reviewStatus === 40
      // ) {
      //   this.formdata.reviewStatus = 20
      // }
      const data = { ...this.formdata }

      if (data.reviewStatus === 40 && data.radio) {
        data.reviewStatus = data.radio
      }

      // console.log(data, '审核提交数据')
      this.$refs.fromdata.validate((valid) => {
        if (valid) {
          financeAdmittanceApi.approvalCommit(data).then((res) => {
            if (res.data) {
              this.success('提交成功')
              // this.$router.push({ path: this.tags.path })
              this.$router.back()
            }
          })
        }
      })
    },
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      if (column.property === 'docName') {
        const _row = row.docIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'fileName') {
        const _row = row.currentFileIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (column.property === 'action') {
        const _row = row.currentFileIdRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 获取盖章文件
    getSignDetail () {
      this.api
        .getSignDetail({
          taskId: Storage.getSession('examine').finBusuinessInfoVO.signTaskId
        })
        .then((res) => {
          this.tableData = FilseTypeFilterData(
            res.data,
            'docName',
            'docIdRowSpan'
          )
          // this.tableData = filterData(
          //   res.data,
          //   'fileName',
          //   'currentFileIdRowSpan'
          // )
        })
    },
    // 改变审核结果查回退节点
    changeReviewStatus (data) {
      console.log(data, Storage.getSession('examine').taskId, '+++')
      if (data && data === 40) {
        // const params = { taskId: Storage.getSession('examine').taskId }
        // financeAdmittanceApi.approvalBackGetNodeList(params).then((res) => {
        //   console.log(res.data)
        //   if (res.data && res.data.length > 0) {
        //     this.backToProcessOptions = res.data
        //     console.log(this.backToProcessOptions, '++=')
        //   }
        // })
        const params = {
          flag: '1',
          instanceId: Storage.getSession('examine').instanceId,
          taskId: Storage.getSession('examine').taskId
        }

        instanceNodeApi.getInstanceNode(params).then(res => {
          const taskList = res.data
          // res.data.forEach(item => {
          //   if (item.transferredUser) {
          //   // 移交数据存在
          //     taskList.push({
          //       ...item,
          //       name: item.taskName,
          //       assigneeList: [
          //         {
          //           id: item.transferredUser,
          //           name: item.transferredUserName
          //         }
          //       ]
          //     })
          //   } else {
          //     taskList.push({
          //       ...item,
          //       name: item.taskName,
          //       assigneeList: [
          //         {
          //           id: !item.originalUser ? item.assignee : item.originalUser,
          //           name: !item.originalUserName
          //             ? item.assigneeName
          //             : item.originalUserName
          //         }
          //       ]
          //     })
          //   }
          // })
          this.backToProcessOptions = taskList
        })
      } else {
        this.backToProcessOptions = []
        // this.$set(this.formdata, 'reviewType', '')
        this.$set(this.formdata, 'taskDefinitionKey', '')
        this.$set(this.formdata, 'taskDefinitionName', '')
      }
    },
    // 回退节点选择
    changeBackToProcess (data) {
      // console.log(data)
      if (data) {
        const [obj] = this.backToProcessOptions.filter(
          (item) => item.id === data
        )
        if (obj) {
          // this.$set(this.formdata, 'reviewType', obj.reviewType)
          this.$set(this.formdata, 'taskDefinitionKey', obj.id)
          this.$set(
            this.formdata,
            'taskDefinitionName',
            obj.name
          )
        }
      } else {
        // this.$set(this.formdata, 'reviewType', '')
        this.$set(this.formdata, 'taskDefinitionKey', '')
        this.$set(this.formdata, 'taskDefinitionName', '')
      }
    },
    // 上传文件成功后赋值文件名
    inputSuccess (name) {
      this.formdata.uploadFileName = name
    },
    // 关闭当前页面
    close () {
      // this.$router.push({ path: this.tags.path })
      this.$router.back()
    },
    // 获取审核详情
    getdetail () {
      const listData = Storage.getSession('examine').finBusuinessInfoVO
      const params = {
        businessId: listData.keyId,
        processEnum: listData.statusCode,
        batchNum: listData.batchNum || null
      }
      financeAdmittanceApi.approvalHandle(params).then((res) => {
        this.finReviewOpinionInfoVO = res.data.finReviewOpinionInfoVO
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.signReview {
  height: 95%;
}
.contentMain {
  display: flex;
  height: 100%;
  justify-content: space-between;
  .left {
    flex: 1;
    margin-right: 8px;
    background: #fff;
  }
  .right {
    width: 449px;
    min-width: 449px;
    background: #fff;
    overflow-y: auto;
  }
}
.submit {
  padding: 9px 0;
  text-align: center;
  background: #F5F5F5;
}
/deep/ .el-form-item__content {
  height: auto !important;
}
</style>
