<template>
  <div class="back">
    <i class="iconfont iconfanhui1" @click="jump(back)"></i
    ><span @click="jump(back)">{{ tags.menuName }}</span>
  </div>
</template>
<script>
export default {
  computed: {
    tags () {
      return JSON.parse(sessionStorage.getItem('routerList'))
    }
  },
  data () {
    return {
      currentPath: '/',
      // 用来缓存所有菜单的配置项
      caches: Object.create(null),
      left: 0,
      top: 0,
      selectedTag: {},
      visible: false,
      tagNames: []
    }
  },
  props: {
    // 现已修改为 无需传入参数 从缓存中获取上一路由信息
    lable: String,
    back: String
  },
  mounted () {},
  methods: {
    jump () {
      this.$router.push({ path: this.tags.path })
    }
  }
}
</script>
<style lang="scss" scoped>
.back {
  padding: 9px 15px;

  display: flex;
  align-items: center;
  i {
    color: #96B5FF;
    font-size: 20px;
    cursor: pointer;
    display: block;
  }
  span {
    display: block;
    padding-left: 10px;
    font-size: 12px;
    color: #757380;
    cursor: pointer;
    font-weight: 400;
  }
}
</style>
