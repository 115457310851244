<template>
  <!-- 审批详情组件 -->
  <div class="auditTimeline">
    <title-template :name="name">
      <!-- <span slot="operation" class="status" v-if="auditInfo.fileMakeName">{{auditInfo.fileMakeName}}</span> -->
    </title-template>
    <el-collapse v-model="activeNames" >
      <el-collapse-item  v-for="(model,index) in dataList" :key="index"  :title="model.processName" :name="(index+1).toString()">
        <el-timeline style="margin-top:10px;">
      <el-timeline-item
        v-for="(item, index) in model.list"
        :key="index"
        placement="top"
        :icon="item.icon"
        :color="item.color"
        :size="item.size ? item.size : 'normal'"
      >
        <slot name="timestamp">
          <span v-if="item.partakeRole == 1" class="timestamp">申请
          <!-- 注释,现在就两种状态通过和未通过 2022/5/19 陈玉玲 -->
            <!-- <span
              class="status"
              style="margin-left:10px;"
              v-if="index === 0 && auditInfo.fileMakeName"
              >{{ auditInfo.fileMakeName }}</span
            > -->
            <i
              class="iconfont"
              :class="showIcon(item)
              "
            ></i>
          </span>
          <span v-else-if="item.partakeRole == 2" class="timestamp"
            >审批
          <!-- 注释,现在就两种状态通过和未通过 2022/5/19 陈玉玲 -->
            <!-- <span
              class="status"
              style="margin-left:10px;"
              v-if="index === 0 && auditInfo.fileMakeName"
              >{{ auditInfo.fileMakeName }}</span
            > -->
            <i
              class="iconfont"
               :class="showIcon(item)"
            ></i>
          </span>
        </slot>
        <el-card>
          <p>
            {{ item.partakeRole == 1 ? "申请" : "审批" }}角色：{{
              item.reviewRoleName
            }}
          </p>
          <p>
            {{ item.partakeRole == 1 ? "申请" : "审批" }}人：{{
              item.reviewMemberName
            }}
          </p>
          <p v-if="item.partakeRole == 2">
            {{ item.partakeRole == 1 ? "申请" : "审批" }}结果：<span
              :class="item.reviewStatus == 30 ? 'noPass' : ''"
              >{{ item.reviewStatus | reviewStatusName(statusList) }}</span
            >
          </p>
          <p v-if="item.partakeRole == 2">
            {{ item.partakeRole == 1 ? "申请" : "审批" }}意见：{{
              item.reviewOpinion
            }}
          </p>
          <p
            @click="downloadFile(item.uploadFileId)"
            v-if="item.uploadFileId && item.uploadFileId != 0"
            style="display: flex;"
          >
            附件：
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.uploadFileName"
              placement="top"
              :disabled="disabled[item.fileIndex]"
            >
              <span class="fileName" ref="valuebox">{{
                item.uploadFileName
              }}</span>
            </el-tooltip>
          </p>
          <p>
            {{ item.partakeRole == 1 ? "申请" : "审批" }}时间：{{
              getdate(item.reviewTime)
            }}
          </p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
      </el-collapse-item>

    </el-collapse>

  </div>
</template>
<script>
import titleTemplate from './titleTemplate.vue'
import { formatDate } from '@/utils/auth/common'
import { fileAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
export default {
  components: { titleTemplate },
  props: {
    dataList: Array,
    auditInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    name: {
      type: String,
      default: '审批履历'
    }
  },
  watch: {
    dataList (val) {
      let i = -1
      val.forEach((ele, index) => {
        if (index === 0 && this.auditInfo.fileMakeName) {
          ele.icon = 'el-icon-more'
          ele.color = '#2862E7'
          ele.size = 'large'
        }
        if (ele.uploadFileId && ele.uploadFileId !== '0') {
          // 处理是第几个附件
          i++
          ele.fileIndex = i
        }
        // 处理格式化审批意见
        ele.list && ele.list.forEach(it => {
          if (it.reviewOpinion) {
            // 常规成对标签
            const reg = /<(?<n1>\w*)[^>]*>(.*)<\/\k<n1>>/g
            // 单标签
            const reg2 = /<.*\/>/g
            while (reg.test(it.reviewOpinion)) {
              it.reviewOpinion = it.reviewOpinion.replace(reg, '$2')
            }
            it.reviewOpinion = it.reviewOpinion.replace(reg2, '')
          }
        })
      })
      this.$nextTick(() => {
        this.getwidth()
      })
    }
  },
  data: function () {
    return {
      activeNames: '1',

      statusList: [
        { code: 10, name: '审核通过' },
        { code: 20, name: '返回修改' },
        { code: 30, name: '拒绝' },
        { code: 40, name: '退回直送' }
      ],
      disabled: []
    }
  },
  filters: {
    // 获取审核状态名称
    reviewStatusName (code, statusList) {
      const [obj] = statusList.filter(item => item.code === code)
      return obj ? obj.name : ''
    }
  },
  methods: {
    // 审核icon显示
    showIcon (row) {
      if (row.partakeRole === 1) {
        return row.reviewStatus === 30 ? 'iconchenggong' : 'iconshibai'
      } else if (row.partakeRole === 2) {
        return row.reviewStatus === 10 ? 'iconchenggong' : 'iconshibai'
      }
    },
    getdate (val) {
      return formatDate(val, 'YY-MM-DD hh:mm:ss')
    },
    // 设置附件是否显示tooltip
    getwidth () {
      this.dom = this.$refs.valuebox
      if (this.dom) {
        this.dom.forEach((item, index) => {
          this.$set(this.disabled, index, item.offsetWidth >= item.scrollWidth)
        })
      }
    },
    downloadFile (id) {
      const params = {
        keyId: id
      }
      if (id) {
        fileAPi.download(params).then(res => {
          downFile(res)
        })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-collapse-item__arrow{
  margin: 5px 8px 0 auto;
}
/deep/ .el-collapse-item__header{
  padding-left:10px ;
}
.auditTimeline {
  .status {
    padding: 3px 7px;
    font-size: 14px;
    color: #50b8ef;
    background: #e3f5ff;
    border-radius: 4px;
    margin-right: 10px;
  }
  .noPass {
    color: #ee1c1c;
  }
  /deep/ .el-timeline-item__wrapper {
    padding-left: 20px;
  }
  /deep/ {
    .el-timeline-item__node--large {
      height: 20px;
      width: 20px;
      left: -6px;
      top: -5px;
      display: flex;
      align-items: center;
    }
    .el-timeline-item__icon {
      margin-top: 3px;
      font-size: 8px;
      height: 10px;
      zoom: 0.6;
      &::before {
        content: "NEW";
      }
    }
  }
  .el-timeline-item {
    padding-bottom: 8px;
  }
  .el-card {
    border: none;
    box-shadow: none;
    /deep/ .el-card__body {
      padding: 0px;
      p {
        color: #757380;
        font-size: 14px;
        margin: 0px;
        margin-bottom: 10px;
        word-wrap: break-word;
      }
    }
  }
  .fileName {
    color: #2862E7;
    text-decoration: underline;
    cursor: pointer;
    width: calc(100% - 42px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .timestamp {
    position: relative;
    top: -10px;
    .iconshibai {
      color: #ff4141;
      font-size: 20px;
      position: absolute;
      top: -7px;
      right: -25px;
    }
    .iconchenggong {
      color: rgb(3, 192, 3);
      font-size: 20px;
      position: absolute;
      top: -7px;
      right: -25px;
    }
  }
}
</style>
