import http from '@/utils/http'
import Rest from '@/utils/http/Rest'
export default class Business extends Rest {
  // 新增融资申请信息(废弃)
  saveAdmittance (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/save`,
      data
    })
  }

  // 新增融资申请信息
  newSaveAdmittance (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/finance/admittance/create',
      data
    })
  }

  // 校验付款单是否被使用
  check (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/check`,
      params
    })
  }

  // 提交资申请信息
  submitAdmittance (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/approval/submit`,
      data
    })
  }

  // 融资申请基本信息
  getPageList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getPage`,
      params
    })
  }

  // 融资申请基本信息
  baseAdminttanceInfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  // 标的资产信息
  assetsInfo (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/underlyingAssetsList`,
      params
    })
  }

  // 备案信息
  getAdmittanceFinling (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getAdmittanceFinling`,
      params
    })
  }

  // 获取准入附件信息
  getAdmittanceFile (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getAdmittanceFile`,
      params
    })
  }

  // 我司合作明细
  getCooperationDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/cooperationDetail`,
      params
    })
  }

  // 删除准入业务
  delAdmittance (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete`,
      params
    })
  }

  // 保存备案条款
  saveFilingTerms (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveFilingTerms/submit?businessId=${data.businessId}`,
      data: data.data
    })
  }

  // 保存附件信息
  saveAdmittanceFile (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveAdmittanceFile`,
      data
    })
  }

  // 获取合同信息
  getContract (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/contract`,
      params
    })
  }

  // 保存标的资产信息
  saveOrUpdateContract (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveOrUpdateContract`,
      data
    })
  }

  // 业务申请文件生成
  genarate (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/genarate`,
      data
    })
  }

  // 企业担保信息查询
  getEnterpriseList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getEnterpriseList`,
      params
    })
  }

  // 个人担保信息查询
  getPersonnalList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/getPersonnalList`,
      params
    })
  }

  // 保存企业担保信息
  saveOrUpdateEnterprise (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveOrUpdateEnterprise`,
      data
    })
  }

  // 删除企业担保数据
  delByEnterprise (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delByEnterprise`,
      data
    })
  }

  // 保存个人担保信息
  saveOrUpdatePersonnal (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveOrUpdatePersonnal`,
      data
    })
  }

  // 删除个人担保数据
  delByPersonnal (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delByPersonnal`,
      data
    })
  }

  // 删除个人担保关联数据
  delPersonalContact (data) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delPersonalContact`,
      data
    })
  }

  // 提交备案条款
  submitFilingTerms (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveFilingTerms/submit?businessId=${data.businessId}`,
      data: data.data
    })
  }

  // 提交标地资产
  submitOrUpdateContract (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/saveOrUpdateContract/submit`,
      data
    })
  }

  // 融资业务审核分页
  getApprovalTodoList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/approval/todo`,
      params
    })
  }

  // 融资业务审核》审核内容查询
  approvalHandle (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/approval/handle`,
      params
    })
  }

  // 提交所有审核
  approvalCommit (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/approval/commit`,
      data
    })
  }

  // 融资业务作废
  approvalAbandon (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/approval/abandon`,
      data
    })
  }

  // 审核查询返回节点
  approvalBackGetNodeList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/approval/back/getNodeList`,
      params
    })
  }

  // 审核判断是否单据存在
  whetherDiscard (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/approval/whether/discard`,
      params
    })
  }

  /**
   * 获取流程历史节点
   * @param {*} params
   * @returns
   */
  getInstanceNode (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }
}
