<template>
  <div class="title">
     <el-tooltip
        class="item"
        effect="dark"
        :content="name"
        placement="top"
        :disabled="disabled"
      >
        <span class="name" ref="valuebox">{{name}}</span></el-tooltip
      >
    <slot name="operation"></slot>
    <div class="line"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      disabled: true
    }
  },
  props: {
    name: String
  },
  watch: {
    name (val) {
      this.getDisabled()
    }
  },
  mounted () {
    window.addEventListener('resize', this.getDisabled, false)
  },
  methods: {
    getDisabled () {
      this.$nextTick(() => {
        this.dom = this.$refs.valuebox
        this.disabled = this.dom.offsetWidth >= this.dom.scrollWidth
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getDisabled)
  }
}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  position: relative;
  .line{
    position: absolute;
    top: 52px;
    left: 17px;
    background: #DEEFE8;
    width: calc(100% - 17px);
    height: 1px;
  }
  .name{
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    color: #38373A;
    line-height: 18px;
    position: relative;
    padding: 19px 16px;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::before {
      content: '';
      width: 6px;
      height: 24px;
      display: block;
      background: #2862E7;
      position: absolute;
      top: 16px;
      left: 0px;
    }

  }
}
</style>
