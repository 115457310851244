var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signReview"},[_c('backTitle'),_c('div',{staticClass:"contentMain"},[_c('div',{staticClass:"left"},[_c('base-table',{staticClass:"main-page-table",attrs:{"columns":_vm.columns,"showPage":false,"tableAttrs":{
          stripe: false,
          data: _vm.tableData,
          border: true,
          spanMethod: _vm.objectSpanMethod,
        },"dataSource":_vm.tableData,"api":_vm.api,"loadCount":_vm.loadCount,"webPage":false},on:{"update:dataSource":function($event){_vm.tableData=$event},"update:data-source":function($event){_vm.tableData=$event}},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"action",fn:function(scope){return [_c('icon-button',{attrs:{"content":"预览","icon":"iconfont iconyulan"},on:{"click":function($event){return _vm.previewfile(scope.row)}}})]}}])},[_vm._v(" > ")])],1),_c('div',{staticClass:"right"},[(_vm.type === 'E')?_c('div',[_c('titleTemplate',{attrs:{"name":"申请意见"}}),_c('base-from',{ref:"fromdata",staticClass:"formStyle",attrs:{"showBtns":false,"componentList":_vm.formConfigListCofig,"formAttrs":{
            model: _vm.formdata,
            labelWidth: '50px',
          }}}),_c('div',{staticClass:"submit"},[_c('baseButton',{attrs:{"label":"提交"},on:{"click":function($event){$event.preventDefault();return _vm.submit(true)}}}),_c('baseButton',{attrs:{"label":"关闭"},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}})],1)],1):_vm._e(),_c('audit-timeline',{staticClass:"auditTimeline",attrs:{"dataList":_vm.finReviewOpinionInfoVO,"name":'审批详情',"auditInfo":{ fileMakeName: '进行中' }}})],1)]),_c('pre-view',{attrs:{"fileId":_vm.fileData.fileId,"isOpen":true,"fileType":_vm.fileData.fileType,"count":_vm.count}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }