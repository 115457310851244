// 盖章序合并数据处理
export function seqFilterData (arr = [], innerKey, orderKey) {
  const res = []
  const map = {}
  arr.forEach((item) => {
    const key = item[innerKey]
    item[orderKey] = 0
    if (!map[key]) {
      map[key] = [item]
    } else {
      map[key].push(item)
    }
  })
  for (const key in map) {
    const list = map[key]
    list[0][orderKey] = list.length
    if (innerKey === 'seq') {
      res.push(...filterData(list, 'docName', 'docNameRowSpan'))
    } else {
      res.push(...list)
    }
  }
  return res
}
// 表格合并数据处理
export function filterData (arr = [], innerKey, orderKey) {
  const res = []
  const map = {}
  arr.forEach((item) => {
    const key = item[innerKey]
    item[orderKey] = 0
    if (!map[key]) {
      map[key] = [item]
    } else {
      map[key].push(item)
    }
  })
  for (const key in map) {
    const list = map[key]
    list[0][orderKey] = list.length
    res.push(...list)
  }
  return res
}

// 表格合并,文件类型,文件名合并
export function FilseTypeFilterData (arr = [], innerKey, orderKey) {
  const res = []
  const map = {}
  arr.forEach((item) => {
    const key = item[innerKey]
    item[orderKey] = 0
    if (!map[key]) {
      map[key] = [item]
    } else {
      map[key].push(item)
    }
  })
  for (const key in map) {
    const list = map[key]
    list[0][orderKey] = list.length
    // 第二次合并
    if (innerKey === 'docName') {
      res.push(...filterData(list, 'currentFileId', 'currentFileIdRowSpan'))
    } else {
      res.push(...list)
    }
  }
  return res
}
